import React, { useEffect } from 'react';

import { navigate } from 'gatsby';
import { useSelector} from 'react-redux';
import { createLoadingSelector } from '../../reducers/api';
import Layout from '../../components/v2/Layout';

import LoaderView from '../../components/LoaderView';

export default function Membership(props) {
  const isLoading = useSelector(createLoadingSelector(['membership/me/cards/get']));
  

  useEffect(() => {
    navigate('/partners/login')
  }, []);

  return (
    <Layout bg='bg2' title='สมาชิก'>
      <LoaderView loading={isLoading} >
        
      </LoaderView>
    </Layout >
  )
}

